import { createApp } from 'vue'
import 'tailwindcss/tailwind.css'
import './index.css'
import App from './App.vue'
import { config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const app = createApp(App);

config.styleDefault = "duotone";
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
