<template>
    <header class="absolute inset-x-0 top-0 z-50">
        <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <a href="#" class="-m-1.5 p-1.5">
                    <img class="andrew-pic h-36 w-48 rounded-full w-auto" src="./../assets/profilepic.jpg" alt="" />
                </a>
            </div>
                <div class="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
        </nav>
    </header>
</template>

<script setup>
</script>