<template>
    <div class="pb-10 bg-gradient-to-r bg-black">
        <TopHeader></TopHeader>
        <div class="relative isolate px-6 pt-14 lg:px-8">
            <div class="mx-auto w-10/12 sm:py-36 lg:py-36">
                <div class="text-center">
                    <h1 class="h-10 top-text pixel-font name">Andrew Pickard</h1>
                    <div class="h-32 slug-12-sm"></div>
                </div>
                <h3 class="h-1 pixel-font text-center resume-header">
                    <button @click="downloadResume">
                        <i class="fas fa-file-download text-2xl" style="margin-right: 20px;"></i> 
                        <div class="" style="display: inline-block;">Resume</div>
                    </button>
                </h3>
            </div>
        </div>
        <div class="deck-stack bg-gradient-to-r">
            <CardDeck title="Education" :deck=eduDeck />
            <div class="slug-32-lg"></div>
            <CardDeck title="Certifications" :deck="certsDeck"/>
            <div class="slug-32-lg"></div>
            <CardDeck title="Work" :deck="workDeck"/>
            <div class="slug-32-lg"></div>
            <SliderCard/>
        </div>
    </div>
</template>
    
<script setup>
    import AOS from 'aos';
    import 'aos/dist/aos.css';
    import CardDeck from './components/CardDeck.vue';
    import TopHeader from './components/TopHeader.vue';
    import SliderCard from './components/SliderCard.vue'
    import { register } from 'swiper/element/bundle';
    import '@fortawesome/fontawesome-free/css/all.css'

    register();
    AOS.init();    

    const GCC_BODY = ["Computer Science A.S.", "Fall 2018 - Fall 2019"]
    const RIT_BODY = ["Software Engineering B.S.", "Spring 2020 - Spring 2023"]
    const GNOS_BODY = ["Full Stack Software Engineer", "Since July 2023"]
    const RIT_WORK_BODY = ["Full Stack Software Engineer / Team Lead", "October 2022 - July 2023"]
    const SECURITY_PLUS_BODY = ["May 2024 - May 2027"]
    const AWS_BODY = ["October 2024 - October 2027"]

    const eduDeck = [
        { filename: 'rit3.jpg', imgAlt: 'Pic of RIT', title: 'Rochester Institute of Technology', body: RIT_BODY, link: "https://www.rit.edu/"},    
        { filename: 'gcc3.jpg', imgAlt: 'Pic of GCC', title: 'Genesee Community College', body: GCC_BODY, link: "https://www.genesee.edu/"}
    ]

    const certsDeck = [
        { filename: 'securityPlus.jpg', imgClass: 'comptia-img', imgAlt: 'Pic of Security +', title: 'CompTIA Security+', body: SECURITY_PLUS_BODY, link: "https://www.comptia.org/certifications/security"},
        { filename: 'awsDevAss.png', imgClass: 'aws-dev-img', imgAlt: 'Badge of AWS Associate Developer', title: 'Amazon AWS Associate Developer', body: AWS_BODY, link: "https://aws.amazon.com/certification/certified-developer-associate/"}
    ]

    const workDeck = [
        { filename: 'gnos2.png', imgAlt: 'Pic of Gnostech', title: 'Currently working at Gnostech LLC', body: GNOS_BODY, link: "https://gnostech.com/"},
        { filename: 'ritgsl.jpg', imgAlt: 'Pic of RIT', title: 'RIT Global Cyber Institute', body: RIT_WORK_BODY, link: "https://www.rit.edu/cybersecurity/"}
    ]

    const downloadResume = () => {
        // URL of the PDF file
        const pdfUrl = 'https://andrew-pickard.com/APResume.pdf';
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'APResume.pdf';
        // Programmatically trigger a click event
        link.click();
        // Clean up
        link.remove();
    };

</script>